import * as React from "react"
import { graphql } from "gatsby"

import PageLayout from "../templates/page-layout"
import Seo from "../components/seo"
import MarketingHero from "../components/marketing-hero"
import MarketingSteps from "../components/marketing-steps"
import MarketingFeatures from "../components/marketing-features"
import MarketingProductivity from "../components/marketing-productivity"
import MarketingCta from "../components/marketing-cta"

const Index = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`

	return (
		<PageLayout location={location} title={siteTitle}>
			<Seo title="Exploo - Video Management" description="Exploo helps your team manage their video recordings. It is like YouTube, but it is private and just for your team." />

			<div className="space-y-16 lg:space-y-32">
				<MarketingHero />
				<MarketingProductivity />
				<MarketingSteps />
				<MarketingFeatures />
				<MarketingCta />
			</div>
		</PageLayout>
	)
}

export default Index

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`