import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default class MarketingFeatures extends React.Component {
	render() {
		return (
			<div className="space-y-16 lg:space-y-32 pt-4">
				<section className="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 lg:px-8">
					<div className="max-w-xl mx-auto lg:max-w-none mx-0 lg:px-0 space-y-8">
						<h2 className="text-center lg:text-left text-3xl font-extrabold leading-loose tracking-tight">
							<div className="inline-block -rotate-3 transform"><span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 py-1 lg:py-2 px-2 lg:px-4 text-white">Quick</span></div>&nbsp;to setup
						</h2>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">With just a few clicks you sign up and create a video dashboard for your team.</p>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Share the invitation link with your team and upload your first videos. 🚀</p>
					</div>

					<div className="overflow-hidden mt-12 sm:mt-16 lg:mt-0">
						<div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
							<StaticImage src="../images/marketing-invite.png" alt="Quickly invite your whole team" width={646} height={559} quality={100} className="w-full" />
						</div>
					</div>
				</section>

				<section className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
					<div className="max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2 space-y-8">
						<h2 className="text-center lg:text-left text-3xl font-extrabold leading-loose tracking-tight">
							<div className="inline-block rotate-2 transform"><span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 py-2 px-4 text-white">Organize</span></div>&nbsp;by tags
						</h2>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Exploo gives you a simple &amp; clean dashboard to manage all your videos in one place.</p>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Add hashtags to organize the way you think and to quickly find and organize videos however you like.</p>
					</div>
					<div className="overflow-hidden mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
						<div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
							<StaticImage src="../images/marketing-tags.png" alt="Organize by tags" width={646} height={559} quality={100} className="w-full" />
						</div>
					</div>
				</section>

				<section className="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 lg:px-8">
					<div className="max-w-xl mx-auto lg:max-w-none mx-0 lg:px-0 space-y-8">
						<h2 className="text-center lg:text-left text-3xl font-extrabold leading-loose tracking-tight">
							<div className="inline-block -rotate-3 transform"><span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 py-1 lg:py-2 px-2 lg:px-4 text-white">Group</span></div>&nbsp;videos to playlists
						</h2>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Use playlists to group videos that belong together, either by topic or theme.</p>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Create your own onboarding series, learning paths, etc. and share the playlist with your team members.</p>
					</div>

					<div className="overflow-hidden mt-12 sm:mt-16 lg:mt-0">
						<div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
							<StaticImage src="../images/marketing-playlist.png" alt="Organize by tags" width={646} height={559} quality={100} className="w-full" />
						</div>
					</div>
				</section>

				<section className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
					<div className="max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2 space-y-8">
						<h2 className="text-center lg:text-left text-3xl font-extrabold leading-loose tracking-tight">
							<div className="inline-block rotate-2 transform"><span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 py-2 px-4 text-white">Engange</span></div>&nbsp;with your team
						</h2>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Ask questions, share your thoughts and engange with your team right under a video.</p>

						<p className="text-center lg:text-left max-w-lg text-xl text-gray-800 leading-9 font-normal">Exploo is a place to collaborate with your team members on video recordings.</p>
					</div>
					<div className="overflow-hidden mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
						<div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
							<StaticImage src="../images/marketing-engange.png" alt="Engange with your team" width={646} height={559} quality={100} className="w-full" />
						</div>
					</div>
				</section>
			</div>
		)
	}
}
  