import * as React from "react"

export default function MarketingProductivity() {
	return (
		<section className="space-y-10 text-center">
			<h2 className="text-3xl lg:text-4xl tracking-tight text-gray-900 font-semibold">Increase your productivity in</h2>

			<div className="text-3xl lg:text-4xl tracking-tight text-gray-900 font-semibold leading-11">
				<span className="rounded-md bg-gray-900 text-white px-3 py-1">work</span>, <span className="rounded-md bg-indigo-600 text-white px-3 py-1">life</span> &amp; <span className="rounded-md bg-green-600 text-white px-3 py-1">school</span>
			</div>

			<p className="mx-auto max-w-3xl text-xl lg:text-2xl text-gray-700 leading-9 lg:leading-10 font-normal">
				Exploo has your back - empowering you with meeting, presentation and workshop recordings that are stored in a central, secure, and searchable place so you and your team can be more engaged, collaborative, and productive.
			</p>
		</section>
	)
}
  