import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function MarketingSteps() {
	return (
		<section>
			<div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
				<div className="mt-14 grid gap-16 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
					<div className="text-center md:text-left">
						<div className="rounded-xl"><StaticImage src={'../images/marketing-step1.png'} alt={'Exploo Step 1'} className="w-full" /></div>
						<h3 className="mt-6 mb-4 md:mb-5 text-lg font-semibold leading-loose tracking-tight">
							<div className="mr-2 inline-block -rotate-3 transform">
								<span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 p-2 text-xl text-white">Step 1</span>
							</div>
							Record anything
						</h3>
						<p className="mt-2 text-base text-gray-600">
							Record onboardings, meetings, workshops, etc. using your existing tools (Zoom, MS Teams, Loom).
						</p>
					</div>

					<div className="text-center md:text-left">
						<StaticImage src={'../images/marketing-step2.png'} alt={'Exploo Step 2'} className="w-full" />
						<h3 className="mt-6 mb-4 md:mb-5 text-lg font-semibold leading-loose tracking-tight">
							<div className="mr-2 inline-block rotate-2 transform">
								<span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 p-2 text-xl text-white">Step 2</span>
							</div>
							Upload to Exploo
						</h3>
						<p className="mt-2 text-base text-gray-600">
							Upload your recordings to Exploo and make them available to your team members.
						</p>
					</div>

					<div className="text-center md:text-left">
						<StaticImage src={'../images/marketing-step3.png'} alt={'Exploo Step 3'} className="w-full" />
						<h3 className="mt-6 mb-4 text-lg font-semibold leading-loose tracking-tight">
							<div className="mr-2 inline-block -rotate-3 transform">
								<span className="rounded-md bg-gradient-to-tr from-pink-500 to-red-500 p-2 text-xl text-white">Step 3</span>
							</div>
							Search, watch and engange
						</h3>
						<p className="mt-2 text-base text-gray-600">
							Search, watch and manage your video library. Share critical information with your team.
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}
  