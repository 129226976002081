import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function MarketingCta() {
	return (
		<section>
			<div className="max-w-7xl mx-auto px-4">
				<div className="bg-gray-900 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
					<div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
						<div className="lg:self-center">
							<h2 className="text-3xl font-extrabold text-white sm:text-4xl">
								<span className="block mb-2">Ready to share videos in your team?</span>
								<span className="bg-gradient-to-tr from-pink-500 to-red-500 bg-clip-text pb-2 text-transparent">Try Exploo.</span>
							</h2>
							<p className="mt-4 text-lg leading-6 text-gray-200">Exploo is like YouTube, but it is private and just for your team.</p>
							<a href="https://exploo.com/app/signup" className="inline-flex items-center justify-center rounded-md px-4 py-2 text-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gradient-to-tr from-pink-500 to-red-500 text-white hover:from-pink-600 hover:to-red-600 hover:shadow-lg hover:shadow-pink-500/40 focus:ring-pink-500 focus:ring-offset-pink-50 text-xl mt-8">Get started</a>
						</div>
					</div>
					<div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
						<div className="rounded-md object-left-top">
							<StaticImage src="../images/marketing-cta.png" alt="Exploo - Ready to share videos with your team?" quality={100} className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
  