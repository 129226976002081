import * as React from "react"
import MarketingTrustedBy from "../components/marketing-trusted-by"
import { StaticImage } from "gatsby-plugin-image"

export default class MarketingHero extends React.Component {
	render() {
		return (
			<header>
				<div className="overflow-hidden">
					<div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8 xl:pt-16">
						<div className="text-center space-y-8">
							<h1 className="text-6xl lg:text-7xl tracking-tight text-gray-900 font-bold">Meet Exploo</h1>

							<p className="mx-auto max-w-3xl text-xl lg:text-3xl text-gray-700 leading-9 lg:leading-10 font-normal">
								Exploo is like YouTube, but it is <span className="bg-gray-100 rounded-md px-2 py-1 font-medium">private and just for your team.</span>
							</p>

							<div className="space-y-2">
								<div className="flex justify-center mx-auto w-full">
									<a href="https://exploo.com/app/signup" className="inline-flex items-center justify-center rounded-md px-4 py-2 text-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gradient-to-tr from-pink-500 to-red-500 text-white hover:from-pink-600 hover:to-red-600 hover:shadow-lg hover:shadow-pink-500/40 focus:ring-pink-500 focus:ring-offset-pink-50 text-xl">Start for free</a>
								</div>

								<p className="max-w-md mx-auto text-xs text-gray-500 leading-4">No CC required 🚀</p>
							</div>
						</div>

						<div className="w-full">
							<StaticImage src="../images/hero-screen.png" alt="Exploo - Ready to share videos with your team?" quality={100} className="w-full my-12" />
						</div>

						<MarketingTrustedBy />
					</div>
				</div>
			</header>
		)
	}
}